@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");

@font-face {
  font-family: circularFont;
  src: url(./assets/css/CircularStd-Book.woff);
}
body {
  margin: 0;
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span, td, input, label {
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

code {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 1000px #17181b inset !important;
    -webkit-text-fill-color: #67757c !important;
    transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}